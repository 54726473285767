
.wrapper {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-areas: "app content";
  grid-gap: 2rem;
  justify-items: center;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  @media(max-width: 1100px) {
    grid-template-columns: 1fr;
    grid-template-areas: "content" "app";
  }
}

.image {
  grid-area: app;
  width: 100%;
}

.content {
  grid-area: content;
}

.submitButtonDesktop {
  @media (max-width: 1100px) {
    display: none;
  }
}

.submitButtonMobile {
  display: none;
  text-align: center;
  margin-top: 1.5rem;
  @media(max-width: 1100px) {
    display: block;
  }
}