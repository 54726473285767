
.container {
  //padding: 150px;
  //display: flex;
  //column-gap: 1.5rem;
  //align-items: flex-end;
  //grid-template-columns: auto 1fr auto;
  //justify-content: center;
  //justify-items: center;
  //max-width: 510px;
  margin: 0 auto;
  &__content {
    position: relative;
    overflow: hidden;
    margin-bottom: 1rem;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background: url('../../assets/img/zaslepka.png') top left no-repeat;
  background-size: 100% auto;
  overflow: hidden;
  &__top {
    position: absolute;
    top: -8%;
    left: 0;
    width: 121%;
    padding-top: 57%;
    transform: rotateZ(-27deg) translateX(-12%) translateY(-33%);
    //background: repeating-conic-gradient(rgb(246, 246, 249) 0%, rgb(246, 246, 249) 25%, transparent 0%, transparent 50%) 50% center / 20px 20px;
    //overflow: hidden;
    &-animated {
      img {
        transition: 200ms ease-out;
      }
    }
  }
  &__topHandle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 63%;
    z-index: 10;
    clip-path: polygon(0 0, 100% 0, 100% 19%, 0% 100%);
  }
  &__topImg {
    display: block;
    //width: 100%;
    position: absolute;
    top: 0;
    //right: 0;
  }
  &__ribbon {
    z-index: 3;
    width: 100%;
    position: absolute;
    top: 9%;
    left: 0;
    img {
      width: 100%;
      display: block;
    }
  }
  &__bottom {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    clip-path: polygon(0 60%, 100% 0, 100% 100%, 0% 100%);
    background: white url('../../assets/img/formatka/formatka_1-1.webp') bottom right no-repeat;
    background-size: cover;
    img {
      position: absolute;
      inset: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__decoration {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60%;
    z-index: 3;
    img {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
}

.toolbar {
  display: flex;
  justify-content: space-between;
  grid-gap: 2rem;
  align-items: flex-start;
  @media(max-width: 768px) {
    grid-gap: 0.75rem;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 0.65rem;
    @media(max-width: 768px) {
      column-gap: 0.5rem;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    font-size: 0.75em;
    text-align: center;
    span {
      padding-top: .1rem;
    }
  }
  &__spacer {
    width: 2rem;
    @media(max-width: 768px) {
      width: 0.5rem;
    }
  }
}
