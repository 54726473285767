
.agreements {
  padding: 1rem 0 0 0.75rem;
  margin-bottom: 1.5rem;
}

.form {
  display: grid;
  grid-template-rows: 1fr auto;
}

.fields {}
