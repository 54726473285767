
.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  justify-items: center;
  padding: 1rem;
  @media(max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.imagePreview {
  display: block;
  width: 100%;
  //max-width: 466px;
  border: 1px solid var(--grey);
}

.content {
  display: flex;
  flex-direction: column;
  //justify-content: flex-end;
  &__top {
    flex: 1;
    //max-width: 350px;
    margin-bottom: 1rem;
  }
  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
  }
}
