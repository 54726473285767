
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontPrimary);
  font-size: 0.75em;
  line-height: 1.25;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--white);
  //border: 1px solid var(--grey);
  border: none;
  background-color: var(--orange);
  padding: 0.25rem 1rem;
  height: 40px;
  text-align: center;
  &:disabled {
    background-color: var(--grey);
    color: var(--white);
  }
}

.buttonSecondary {
  background-color: var(--black);
}
