//@import "./vendors/bootstrap-reboot.css";

:root {
  --orange: #F97412;
  --orangeDark: #d26617;
  --red: #E01B1B;
  --grey: #A0A0A0;
  --lightGrey: #FBFBFB;
  --white: #ffffff;
  --black: #000000;

  --fontPrimary: Helvetica, sans-serif;
  --fontHeadings: Georgia, serif;
}

html, body {
  //font-family: var(--fontPrimary);
}

.verticalOrderedList {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    position: relative;
    //font-size: 0.75em;
    text-align: center;
    //max-width: 380px;
    margin: 0 auto 1.5rem;
    &::before {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--black);
      color: var(--orange);
      font-family: var(--fontHeadings);
      font-size: 1.5rem;
      line-height: 1;
      text-align: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding-bottom: 5px;
      margin: 0 auto 0.8rem;
    }
    &:nth-child(1)::before {
      content: '1';
    }
    &:nth-child(2)::before {
      content: '2';
    }
    &:nth-child(3)::before {
      content: '3';
    }
    &:nth-child(4)::before {
      content: '4';
    }
    &:nth-child(5)::before {
      content: '5';
    }
  }
}

.editArea {
  h1,h2,h3,h4,h5,h6 {
    font-family: var(--fontHeadings);
    line-height: 1.15;
  }
  h1, h2 {
    font-size: 3em;
  }
  h3 {
    font-size: 2em;
  }
  h4 {
    font-size: 1.75em;
  }
  h5 {
    font-size: 1.5em;
  }
  h6 {
    font-size: 1.15em;
  }
  p, ul, ol {
    //font-size: 0.75em;
  }
  img {
    display: inline-block;
    max-width: 100%;
  }
}

.fieldset {
  position: relative;
  display: block;
  margin-bottom: 0.65rem;
  &__input {
    display: block;
    width: 100%;
    border: 1px solid var(--grey);
    color: var(--black);
    font-weight: 500;
    background-color: var(--lightGrey);
    padding: 0.5rem 0.8rem;
    margin-bottom: 0;
    &:placeholder-shown {
      color: var(--grey);
    }
  }
  &__checkbox {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    &:checked~.fieldset__checkboxLabel::before {
      background-color: var(--orange);
    }
  }
  &__checkboxLabel {
    display: block;
    padding-top: 0.25em;
    font-size: 0.55em;
    padding-left: 1.75rem;
    text-align: justify;
    min-height: 1rem;
    &::before {
      content: '';
      position: absolute;
      top: 0.2em;
      left: 0;
      width: 1rem;
      height: 1rem;
      background-color: var(--white);
      border: 1px solid var(--grey);
      cursor: pointer;
    }
  }
  &__error {
    display: block;
    color: var(--red);
    font-size: 0.75em;
    text-align: left;
    padding-top: 0.25rem;
  }
}

.spinner {
  animation: animate 1250ms linear infinite;
  @keyframes animate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.spinner-border {
  &.-sm {
    width: 1em;
    height: 1em;
  }
}

.appIcon {
  display: block;
  width: 136px;
  max-width: 100%;
  margin: 0 auto;
  &.-lg {
    width: 186px;
  }
}
